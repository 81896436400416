import React from "react";
import "./Coaches.css";
import RoundedImage from "../roundedImage/RoundedImage";

type props = {
  isSelected: boolean;
  imageUrl: string;
  name: string;
  ratings: number;
};

export const CoachesHeader: React.FC<props> = ({
  imageUrl,
  isSelected,
  name,
  ratings,
}) => {
  const getInitials = (name: string) => {
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0))
      .join("")
      .slice(0, 2);
    return initials.toUpperCase();
  };
  return (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}
      >
        {imageUrl ? (
          <RoundedImage height={28} width={28} src={imageUrl} />
        ) : (
          <div
            style={{
              height: 28,
              width: 28,
              borderRadius: "50%",
              backgroundColor: "#0A4D43",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 13,
              fontWeight: "600",
            }}
          >
            {getInitials(name)}
          </div>
        )}
        <div style={{ flexDirection: "column", display: "flex" }}>
          <span
            style={{
              marginLeft: 5,
              textAlign: "center",
              fontSize: 13,
              fontWeight: "600",
              color: "#0A4D43",
            }}
          >
            {name}
          </span>
          <span
            style={{
              marginLeft: 5,
              fontSize: 10,
            }}
          >
            {` Ratings: ${ratings}`}
          </span>
        </div>
      </div>
      <div className="radio-button-outer">
        {isSelected && <div className="radio-button-inner" />}
      </div>
    </div>
  );
};
