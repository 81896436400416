import React from "react";
import "./LoadingOverlay.css";

type props = {
  loading: boolean;
};

const LoadingIndicator: React.FC<props> = ({ loading }) => {
  return loading ? (
    <div className="loading-overlay">
      <div className="spinner-border custom-spinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : null;
};

export default LoadingIndicator;
