import { UserRole } from "../types/types";
import { defaultPaths } from "./data";

export const CoachUser: UserRole = {
  value: 2,
  paths: ["/coach-availability", "/coach-plans", ...defaultPaths],
};

export const AdminUser: UserRole = {
  value: 1,
  paths: ["/order-description", "/orders", ...defaultPaths],
};
