import React from "react";
import "./Coaches.css";
import { CoachesHeader } from "./CoachHeader";
import { Coachesfooter } from "./CoachFooter";
import { Coach } from "../../apis/types";
import { Strings } from "../../utils/strings";

type props = {
  coaches: Coach[];
  setSelectedCoach: React.Dispatch<React.SetStateAction<Coach | undefined>>;
  selectedCoach?: Coach;
};

export const Coaches: React.FC<props> = ({
  coaches,
  setSelectedCoach,
  selectedCoach,
}) => {
  const adjustCoachesCountView = (allCoaches?: Coach[]) => {
    if (allCoaches) {
      const adjustedSlots = [...allCoaches];
      const remainder = adjustedSlots.length % 4;
      if (remainder !== 0) {
        // Add dummy slots to make the count a multiple of 3
        const dummySlotsCount = 4 - remainder;
        for (let i = 0; i < dummySlotsCount; i++) {
          adjustedSlots.push({
            id: 0,
            name: "",
            profileImageUrl: "",
            rating: 0,
            countryCode: "",
            feesPerHour: 0,
            bio: "",
            isAvailable: false,
          });
        }
      }
      return adjustedSlots;
    } else {
      return [];
    }
  };

  const handleCoachClicked = (coach: Coach) => {
    if (!coach.isAvailable) return;
    setSelectedCoach(selectedCoach?.id === coach.id ? undefined : coach);
  };

  const renderItem = (item: Coach, index: number, isDisabled: boolean) => {
    if (item.id !== 0) {
      const isSelected = selectedCoach?.id === item.id;
      return (
        <div
          key={index}
          className={
            item.isAvailable ? `coach-grid-item` : `coach-grid-item-disabled`
          }
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          onClick={() => {
            handleCoachClicked(item);
          }}
        >
          <CoachesHeader
            isSelected={isSelected}
            imageUrl={item.profileImageUrl}
            name={item.name}
            ratings={item.rating}
          />
          <p style={{ fontSize: 12, marginTop: 10 }}>{item.bio}</p>
          <button type="button" className="btn more-button" onClick={() => {}}>
            More
          </button>
          <Coachesfooter
            isAvailable={item.isAvailable}
            feesPerHour={item.feesPerHour}
          />
        </div>
      );
    } else {
      return <div key={index} className={`coach-grid-item-transparent`} />;
    }
  };

  return (
    <>
      <p className="available-coaches-text ">{Strings.AVAILABLE_COACHES}</p>
      <div className="coach-grid-container">
        {adjustCoachesCountView(coaches).map((item, index) =>
          renderItem(item, index, !item.isAvailable)
        )}
      </div>
    </>
  );
};
