import React, { useEffect, useState } from "react";
import "./TimeSlot.css";
import { addMinutesToTime, addOneHour } from "../../utils/utils";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import { Strings } from "../../utils/strings";
import { CoachAvailabilityResponse } from "../../apis/types";
import { coachDefaultTimeSlotsData } from "../../utils/data";

type props = {
  date: string;
  setLoading: (value: boolean) => void;
};

const TimeSlotList: React.FC<props> = ({ date, setLoading }) => {
  const [items, setItems] = useState(coachDefaultTimeSlotsData);

  const updateItemsStateInit = (response: CoachAvailabilityResponse[]) => {
    // Create a lookup map from the response
    const availabilityMap = response.reduce((map, avail) => {
      map[avail.startTime.toString()] = avail;
      return map;
    }, {} as Record<string, CoachAvailabilityResponse>);

    // Update items based on the availability map
    const updatedItems = items.map((item) => {
      const availabilityItem = availabilityMap[item.name];

      if (availabilityItem) {
        const state = availabilityItem.isCompleteHour
          ? Strings.FULL
          : Strings.HALF;
        return { ...item, state, id: availabilityItem.id };
      }

      return item;
    });

    setItems(updatedItems);
  };

  useEffect(() => {
    const getAllAvailableTimeSlots = async () => {
      try {
        setLoading(true);
        const response = await FrontDeskApi.getCoachAvailabilities(date, date);
        updateItemsStateInit(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    getAllAvailableTimeSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const postCoachAvailability = async (
    startTime: string,
    endTime: string,
    isAvailable: boolean,
    state: string,
    availabilitySlotId?: number
  ) => {
    console.log(startTime);
    console.log(date);
    const payload = availabilitySlotId
      ? {
          date: date,
          startTime: startTime,
          endTime: endTime,
          isAvailable: isAvailable,
          availabilitySlotId: availabilitySlotId,
        }
      : {
          date: date,
          startTime: startTime,
          endTime: endTime,
          isAvailable: isAvailable,
        };
    try {
      setLoading(true);
      const response = await FrontDeskApi.postCoachAvailabilityForSingleDay(
        payload
      );

      handleCheckboxChange({
        id:
          response?.id ??
          items.find((item) => item.name === startTime)?.id ??
          0,
        name: response?.startTime.toString() ?? startTime,
        state:
          state === Strings.DEFAULT
            ? Strings.FULL
            : state === Strings.FULL
            ? Strings.HALF
            : Strings.DEFAULT,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleCheckboxChange = (timeSlot: {
    id: number;
    name: string;
    state: string;
  }) => {
    setItems((items) =>
      items.map((item) => {
        if (item.id === timeSlot.id) {
          if (item.state === Strings.DEFAULT) {
            return { ...item, state: Strings.FULL };
          } else if (item.state === Strings.FULL) {
            return { ...item, state: Strings.HALF };
          } else {
            return { ...item, state: Strings.DEFAULT };
          }
        } else if (item.name === timeSlot.name) {
          return { ...item, state: timeSlot.state, id: timeSlot.id };
        }
        return item;
      })
    );
  };

  return (
    <div className="coach-timeslot-grid-container">
      {items.map((item, index) => (
        <div key={index}>
          <div
            key={index}
            className={` coach-timeslot-grid-item ${
              item.state === Strings.FULL
                ? "selected"
                : item.state === Strings.HALF
                ? "half-colored"
                : ""
            }`}
            onClick={() => {
              if (item.state === Strings.DEFAULT) {
                postCoachAvailability(
                  item.name,
                  addOneHour(item.name),
                  true,
                  item.state
                );
              } else if (item.state === Strings.FULL) {
                postCoachAvailability(
                  item.name,
                  addMinutesToTime(item.name, 30),
                  true,
                  item.state,
                  item.id
                );
              } else if (item.state === Strings.HALF) {
                postCoachAvailability(
                  item.name,
                  addMinutesToTime(item.name, 30),
                  false,
                  item.state,
                  item.id
                );
              }
            }}
          >
            {item.name}
          </div>
          <p
            style={{
              marginBottom: "-10px",
              alignSelf: "center",
              textAlign: "center",
              fontSize: "8px",
            }}
          >
            {item.state === Strings.FULL
              ? `${item.name} - ${addOneHour(item.name)}`
              : item.state === Strings.HALF
              ? `${item.name} - ${addMinutesToTime(item.name, 30)}`
              : ""}
          </p>
        </div>
      ))}
    </div>
  );
};

export default TimeSlotList;
