import React, { useEffect, useState } from "react";
import TopBar from "../../components/topBar/TopBar";

import {
  convertTo12HourFormatTimeRange,
  formatDateToYYYYMMDD,
  groupSlotsByDate,
} from "../../utils/utils";

import { isMobile } from "react-device-detect";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import "./CoachPlans.css";
import { useFontDesk } from "../../context/frontDeskContext";
import { AiOutlineWarning } from "react-icons/ai";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import { Strings } from "../../utils/strings";
import { Coach, CoachAvailabilityResponse } from "../../apis/types";
import Table from "../../components/table/Table";
import SearchBar from "../../components/searchBar/SearchBar";

export const CoachPlans = () => {
  const { coachAvailabilityWeekRanges } = useFontDesk();
  const [filteredSlots, setFilteredSlots] = useState<{
    [key: string]: CoachAvailabilityResponse[];
  }>();

  const [show, setShow] = useState(false);
  const [coaches, setCoaches] = useState<Coach[]>();
  const [selectedCoach, setSelectedCoach] = useState<Coach[]>([]);
  const [coachError, setCoachError] = useState<string>();
  const [modalData, setModalData] = useState<{
    title: string;
    children: React.ReactNode | string;
    btnText?: string;
    btnVariant?: string;
    onClose: () => void;
    headerIcon?: React.ReactNode;
  }>();
  const [loading, setLoading] = useState(false);

  const { onLogOut } = useFontDesk();

  const handleClose = async (isLogout: boolean = false) => {
    setShow(false);
    setModalData(undefined);
    if (!isLogout) {
      await getAllAvailableTimeSlots();
    }
  };

  const getAllAvailableTimeSlots = async () => {
    try {
      setLoading(true);
      const response = await FrontDeskApi.getCoachAvailabilitiesByCoachId(
        selectedCoach.length ? selectedCoach[0].id : 0,
        formatDateToYYYYMMDD(coachAvailabilityWeekRanges.currentWeekStart),
        formatDateToYYYYMMDD(coachAvailabilityWeekRanges.afterNextWeekEnd)
      );
      setFilteredSlots(groupSlotsByDate(response));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const formatDateToMMDDYYYY = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${month}-${day}-${year}`;
  };

  const prepareTableData = () => {
    let tableData: { date: string; timeSlots: React.ReactNode }[] = [];

    if (filteredSlots) {
      Object.entries(filteredSlots).forEach(([date, valueArray]) => {
        const formattedDate = formatDateToMMDDYYYY(date);
        const timeSlots = valueArray.map((item, index) => {
          return (
            <div
              key={index}
              style={
                {
                  // width: "160px",
                }
              }>
              {renderItem(item, index)}
            </div>
          );
        });

        tableData.push({
          date: formattedDate,
          timeSlots: <div className="box">{timeSlots}</div>,
        });
      });
    }

    return tableData;
  };

  const columns = [
    { header: "Date", accessor: "date", setLimitedWidth: true },
    {
      header: "Time Slots",
      accessor: "timeSlots",
      cell: (row: any) => (
        <div className="time-slot-box-container">
          {row.value.map((slot: string, index: number) => (
            <div key={index} className="time-slot-row">
              <div className={`coach-plans-grid-item selected`}>{slot}</div>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const renderItem = (item: CoachAvailabilityResponse, index: number) => {
    if (item.slotName) {
      const timeFormat = item.slotName.split(" ")[1];
      const timeSlot = convertTo12HourFormatTimeRange(
        item.slotName.split(" ")[0]
      );
      return (
        <div
          key={index}
          className={`coach-plans-grid-item selected ${
            !item.isCompleteHour ? "half-colored" : ""
          }`}
          onClick={() => {}}>
          {`${timeSlot} ${timeFormat}`}
        </div>
      );
    } else {
      return (
        <div key={index} className={`coach-plans-grid-item-transparent`} />
      );
    }
  };

  const renderMobileItem = (item: CoachAvailabilityResponse, index: number) => {
    if (item.slotName) {
      const timeFormat = item.slotName.split(" ")[1];
      const timeSlot = convertTo12HourFormatTimeRange(
        item.slotName.split(" ")[0]
      );
      return (
        <div
          key={index}
          className={`coach-plans-grid-item-mobile selected ${
            !item.isCompleteHour ? "half-colored" : ""
          }`}
          onClick={() => {}}>
          {`${timeSlot} ${timeFormat}`}
        </div>
      );
    } else {
      return (
        <div key={index} className={`coach-plans-grid-item-transparent`} />
      );
    }
  };

  useEffect(() => {
    if (selectedCoach.length) {
      getAllAvailableTimeSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoach]);

  const getPlayersByFilteredName = (filterName: string, pageNum: number) => {
    setLoading(true);
    setCoachError(undefined);
    FrontDeskApi.getCoachesByName(filterName)
      .then((response) => {
        // const filteredUsers = response.filter((el) => {
        //   return !selectedCoach.filter((f) => {
        //     return f.userId === el.userId;
        //   }).length;
        // });
        setCoaches(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setCoaches(undefined);
        setCoachError("No coaches found.");
      });
  };

  return (
    <div className="coach-plans-outer-container">
      <TopBar
        isBack={isMobile}
        onLogout={() => {
          setModalData({
            title: Strings.LOGOUT,
            children: Strings.LOGOUT_WARNING,
            btnText: Strings.LOGOUT,
            btnVariant: "outline-danger",
            headerIcon: (
              <AiOutlineWarning
                style={{
                  marginRight: "0.5em",
                  color: "red",
                  alignSelf: "center",
                }}
              />
            ),
            onClose: () => {
              handleClose(true);
              onLogOut();
            },
          });
          setShow(true);
        }}
      />
      <div className="coach-plans-grid-container">
        <div className="coach-plans_info-container">
          <span className="coach-info">{Strings.COACH_SCHEDULE_INFO}</span>
        </div>
        <SearchBar
          list={
            coaches
              ? coaches.filter((el) => {
                  return !selectedCoach.filter((f) => {
                    return f.id === el.id;
                  }).length;
                })
              : undefined
          }
          onSearchCompleted={(value) => {
            getPlayersByFilteredName(value, 0);
          }}
          onItemClicked={(player) => {
            // if (selectedCoach.length > 3) {
            //   setModalData({
            //     title: Strings.ERROR,
            //     children: "Only four Players are allowed",
            //     btnText: Strings.OK,
            //     onClose: () => {
            //       handleClose(true);
            //     },
            //   });
            //   setShow(true);
            //   return;
            // }
            setFilteredSlots(undefined);
            setSelectedCoach([player]);
            setCoaches(undefined);
          }}
          placeholder={
            selectedCoach.length
              ? selectedCoach[0].name
              : Strings.ENTER_COACH_NAME
          }
        />
        {coachError ? (
          <p style={{ textAlign: "center", fontSize: 13, color: "red" }}>
            {coachError}
          </p>
        ) : null}
        {selectedCoach.length > 0 && (
          <>
            <span className="coach-plans-header">{`${selectedCoach[0]?.name}'s Plans`}</span>
            {filteredSlots ? (
              isMobile ? (
                <div className="list-group plan-mob-list-container">
                  {Object.entries(filteredSlots).map(([date, valueArray]) => (
                    <div
                      className="list-group-item mob-item-container"
                      key={date}>
                      <div className="d-flex w-100">
                        <div style={{ marginBottom: "10px" }}>
                          <b className="mob-key-value">Slot Date:</b>
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                          <b className="date-value">
                            {formatDateToMMDDYYYY(date)}
                          </b>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {valueArray.map((item, index) => (
                          <div
                            key={index}
                            style={{ marginRight: "3px", marginTop: "3px" }}>
                            {renderMobileItem(item, index)}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Table
                  data={prepareTableData()}
                  columns={columns}
                  isShowActionButton={false}
                />
              )
            ) : !loading ? (
              <div style={{ textAlign: "center", fontWeight: "bold" }}>
                No Data Available
              </div>
            ) : null}
          </>
        )}
        <StaticBackdropModal
          show={show}
          handleOpen={modalData?.onClose ?? handleClose}
          handleClose={handleClose}
          title={modalData?.title}
          children={modalData?.children}
          btnText={modalData?.btnText}
          headerIcon={modalData?.headerIcon}
        />
        <LoadingIndicator loading={loading} />
      </div>
    </div>
  );
};
