import React from "react";
import "./TopBar.css";
import ultraLogo from "../../assets/logo_white.png";
import { Button } from "react-bootstrap";
import { Strings } from "../../utils/strings";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
type props = {
  isBack?: boolean;
  onLogout?: () => void;
  onBookPro?: () => void;
};
const TopBar: React.FC<props> = ({ isBack, onLogout, onBookPro }) => {
  const navigate = useNavigate();
  return (
    <div className="topBar fixed-top">
      <div>
        {isBack && (
          <IoIosArrowBack
            style={{ marginRight: "5px" }}
            color="white"
            size={25}
            onClick={() => navigate(-1)}
          />
        )}
        <img src={ultraLogo} className="logo" alt="Description" />
      </div>
      <span className="coachText">{Strings.ULTRA_FRONT_DESK}</span>
      <div>
        {onBookPro && (
          <Button
            className="bookPro-button"
            variant="outline-info"
            onClick={onBookPro}>
            {Strings.BOOK_A_PRO}
          </Button>
        )}
        <Button
          className="logout-button"
          variant="outline-danger"
          onClick={onLogout}>
          {Strings.LOGOUT}
        </Button>
      </div>
    </div>
  );
};

export default TopBar;
