/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import "./Table.css"; // Import custom CSS file for any additional styling
import { ItemStatus } from "../../utils/typeValues";
import { getTextColor, updateTimer } from "../../utils/utils";

// Interfaces for column and props
interface Column {
  header: string;
  accessor: string;
  hiddenOnMobile?: boolean; // Optional flag to hide column on small screens
  setLimitedWidth?: boolean;
}

interface Props {
  data: any[]; // Array of objects representing table rows
  columns: Column[]; // Array of column definitions
  onOrderItemClicked?: (orderName: string) => void;
  onAcceptClicked?: (rowData: any) => void;
  onCancelClicked?: (rowData: any) => void;
  isShowActionButton: boolean;
}

const Table: React.FC<Props> = ({
  data,
  columns,
  onOrderItemClicked,
  onAcceptClicked,
  onCancelClicked,
  isShowActionButton,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | null;
  }>({
    key: "",
    direction: null,
  });

  const onHeaderClick = (accessor: string) => {
    const newDirection =
      sortConfig.key === accessor && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key: accessor, direction: newDirection });
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.direction === "ascending") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    if (sortConfig.direction === "descending") {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="table-container">
      <table className="custom-table-container">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={`custom-header ${
                  column.hiddenOnMobile ? "hidden-on-mobile" : ""
                } ${column.setLimitedWidth ? "fix-header" : ""}`}
                // onClick={() => onHeaderClick(column.accessor)}
              >
                {column.header}
                {sortConfig.key === column.accessor &&
                  (sortConfig.direction === "ascending" ? "▲" : "▼")}
              </th>
            ))}
            {isShowActionButton ? (
              <th style={{ textAlign: "center" }} className="custom-header">
                Actions
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr
              style={{ cursor: onOrderItemClicked ? "pointer" : "" }}
              className="table-data"
              key={rowIndex}
              onClick={() =>
                onOrderItemClicked?.(sortedData[rowIndex].orderId)
              }>
              {columns.map((column, colIndex) => (
                <td
                  className={`table-item ${
                    column.hiddenOnMobile ? "hidden-on-mobile" : ""
                  }`}
                  style={
                    rowIndex === sortedData.length - 1
                      ? {
                          borderBottom: "none",
                          color: getTextColor(row[column.accessor]),
                        }
                      : {
                          color: getTextColor(row[column.accessor]),
                        }
                  }
                  key={colIndex}>
                  {column.accessor === "createdAtData"
                    ? updateTimer(new Date(row[column.accessor]))
                    : row[column.accessor]}
                </td>
              ))}
              {isShowActionButton &&
              sortedData[rowIndex].status === ItemStatus.Pending ? (
                <td
                  colSpan={columns.length - 1} // Span all columns except the last one
                  style={
                    rowIndex !== sortedData.length - 1
                      ? {
                          borderBottomColor: "#f2ebe1",
                          borderBottomWidth: 1,
                          textAlign: "center",
                        }
                      : { textAlign: "center" }
                  }>
                  <button
                    type="button"
                    className="btn verifyBtn"
                    onClick={() => onAcceptClicked?.(sortedData[rowIndex])}>
                    Deliver
                  </button>
                  <button
                    type="button"
                    className="btn cancel"
                    onClick={() => onCancelClicked?.(sortedData[rowIndex])}>
                    Cancel
                  </button>
                </td>
              ) : isShowActionButton ? (
                <td
                  colSpan={columns.length - 1} // Span all columns except the last one
                  style={
                    rowIndex !== sortedData.length - 1
                      ? {
                          borderBottomColor: "#f2ebe1",
                          borderBottomWidth: 1,
                          textAlign: "center",
                        }
                      : { textAlign: "center" }
                  }>
                  -
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
