export const Strings = {
  OTP_VRIFICATION_DONE: "Otp Verification is successfully done",
  OTP_SENT: "OTP Sent",
  OTP_SENT_TO_NUMBER: "An OTP has been sent to your mobile number",
  ERROR_SENDING_OTP: "Error sending OTP",
  INVALID: "Invalid",
  PLEASE_ENTER_VALID_NUMBER: "Please enter valid email",
  SUCCESS: "Success",
  ERROR_OTP_VERIFICATION: "Error in otp code verification",
  ENTER_VALID_OTP: "Please enter valid OTP Code",
  NEW_OTP_SENT: "New OTP has been sent successfully",
  ERROR_SENDING_NEW_OTP: "Error sending new OTP",
  ENTER_EMAIL_NUMBER: "Please enter your email to receive a verification code.",
  EMAIL_ADDRESS: "Email Address",
  VERIFICATION: "Ultra Staff Verification",
  SEND_OTP: "Send OTP",
  CODE_SENT_TO: "We've sent a code to ",
  RESEND: "Resend",
  VERIFY: "Verify",
  DID_NOT_GET_CODE: "Didn't get a code?",
  SELECT_TIME_SLOTS: "Select your time slots for",
  LOGOUT: "Log out",
  BOOK_A_PRO: "Book Pro",
  LOGOUT_WARNING: "Are you sure want to Logout?",
  ORDERS: "Orders",
  NEXT_WEEK: "Next Week",
  AFTER_NEXT_WEEK: "After Next Week",
  ULTRA_FRONT_DESK: "Ultra Staff",
  DEFAULT: "Default",
  FULL: "Full",
  HALF: "Half",
  NO_ORDERS_FOUND: "No Orders Found",
  NO_ORDER_ITEMS_FOUND: "No Order Items Found",
  NOTIFICATION_PERMISSION: "Notification Permission",
  NOTIFICATION_PERMISSION_REQUIRED:
    "Notification Permission is required for using front desk",
  ACCEPT: "Accept",
  ENABLE_NOTIFICATION: "Enable Notification",
  IOS_PERMISSION:
    "You are using IOS Device.To enable notifications, Press share Button and click Add to Home Screen. Then open App from Home Screen",
  ORDER_ITEMS_LIST: "Order Items List",
  ORDER_UPDATED: "Your Order Has Been Updated Successfully",
  OK: "OK",
  ERROR: "Error",
  ORDER_NOT_UPDATED: "Your order couldn't be updated due to an error",
  ERROR_UPDATING_ORDER: "Something went wrong updating your order",
  NOTIFICATION_PERMISSION_DENIED:
    "You have denied permission for notifications. Please enable it in your browser settings",
  NO_PLAYER: "No Players Found",
  ENTER_CUSTOMER_NAME: "Enter Customer Name",
  BOOK_PRO_INFO: "Please enter customer name for which you have to book pro",
  WARNING: "WARNING",
  SELECT_CONTINUE_TIMESLOTS: "Please select contiuous time slots",
  BOOK_PRO_TIME_INFO:
    "Choose the day, time, and court type you want for the lesson",
  BOOK_COACH: "Book Pro",
  AVAILABLE_COACHES: "Available Coaches",
  THIS_WEEK: "This Week",
  COACHES_SCHEDULE: "Coaches Schedule",
  MY_SCHEDULE: "My Schedule",
  COACH_SCHEDULE_INFO:
    "Please enter name of coach for which you have to check schedule information",
  ENTER_COACH_NAME: "Enter coach name",
};
