import React from "react";
import "./SelectedPlayers.css";
import { Player } from "../../apis/types";
import RoundedImage from "../roundedImage/RoundedImage";

type props = {
  players: Player[];
  removePlayer: (player: Player) => void;
};

export const SelectedPlayers: React.FC<props> = ({ players, removePlayer }) => {
  const renderItem = (item: Player, index: number) => {
    return (
      <div
        key={index}
        className={`player-grid-item`}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignContent: "center",
            }}
          >
            <RoundedImage height={28} width={28} src={item.userImageUrl} />
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <span
                style={{
                  marginLeft: 5,
                  textAlign: "center",
                  fontSize: 13,
                  fontWeight: "600",
                  color: "#0A4D43",
                }}
              >
                {item.name}
              </span>
              <span
                style={{
                  marginLeft: 5,
                  fontSize: 10,
                }}
              >
                {`Email: ${item.email}`}
              </span>
            </div>
          </div>
          <button
            type="button"
            className="btn more-button"
            onClick={() => {
              removePlayer(item);
            }}
          >
            Remove
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="player-container">
        {players.map((item, index) => renderItem(item, index))}
      </div>
    </>
  );
};
