/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from "react";
import "./TimeSlot.css";
import { Strings } from "../../utils/strings";
import { SpecificBookingSlot, SpecificBookingSlots } from "../../apis/types";
import { convertTo12HourFormatTimeRange } from "../../utils/utils";

type Props = {
  slots?: SpecificBookingSlots;
  setSelectedSpecificSlots: React.Dispatch<
    React.SetStateAction<SpecificBookingSlot | undefined>
  >;
  selectedSpecificSlots?: SpecificBookingSlot;
  setModalData: React.Dispatch<
    React.SetStateAction<
      | {
          title: string;
          children: React.ReactNode | string;
          btnText?: string;
          btnVariant?: string;
          onClose: () => void;
          onAccept?: () => void;
          headerIcon?: React.ReactNode;
        }
      | undefined
    >
  >;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const TimeSlotList: React.FC<Props> = ({
  slots,
  selectedSpecificSlots,
  setSelectedSpecificSlots,
  setModalData,
  setShow,
}) => {
  const showModal = () => {
    setModalData({
      title: Strings.WARNING,
      children: Strings.SELECT_CONTINUE_TIMESLOTS,
      onAccept: undefined,
      onClose: hideModal,
      btnText: Strings.OK,
    });
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
    setModalData(undefined);
  };

  const isContinuousTimeSlot = useCallback(
    (
      lastSelected: SpecificBookingSlot,
      nextSelected: SpecificBookingSlot,
      item: SpecificBookingSlot
    ) => {
      const comparisonBar = item.endSlotTime - item.startSlotTime;

      return (
        (Math.abs(lastSelected.endSlotTime - item.endSlotTime) ===
          comparisonBar &&
          Math.abs(lastSelected.startSlotTime - item.startSlotTime) ===
            comparisonBar) ||
        (Math.abs(nextSelected.endSlotTime - item.endSlotTime) ===
          comparisonBar &&
          Math.abs(nextSelected.startSlotTime - item.startSlotTime) ===
            comparisonBar)
      );
    },
    []
  );

  const handlePress = (item: SpecificBookingSlot) => {
    // if (item.slotName.endsWith('AM') || item.slotName.endsWith('PM')) {
    //   return;
    // } // Ignore disabled items

    // setSelectedSpecificSlots((prevSelected) => {
    //   const isSelected = prevSelected.some(
    //     (selectedItem) => selectedItem.slotName === item.slotName
    //   );
    //   let newSelected: SpecificBookingSlot[] = [];
    //   if (isSelected) {
    //     // Remove selected item
    //     newSelected = prevSelected.filter(
    //       (selectedItem) => selectedItem.slotName !== item.slotName
    //     );
    //   } else {
    //     const lastSelected = prevSelected[prevSelected.length - 1];
    //     const nextSelected = prevSelected[0];
    //     if (
    //       lastSelected !== undefined &&
    //       nextSelected !== undefined &&
    //       isContinuousTimeSlot(lastSelected, nextSelected, item)
    //     ) {
    //       newSelected = [...prevSelected, item].sort((a, b) => {
    //         const nameA = a.slotName.toUpperCase();
    //         const nameB = b.slotName.toUpperCase();
    //         if (nameA < nameB) {
    //           return -1;
    //         }
    //         if (nameA > nameB) {
    //           return 1;
    //         }
    //         return 0;
    //       });
    //     } else if (prevSelected.length === 0) {
    //       newSelected = [item];
    //     } else {
    //       showModal();
    //       return prevSelected; // Return previous state without changes
    //     }
    //   }
    //   return newSelected;
    // });

    setSelectedSpecificSlots(
      selectedSpecificSlots?.startSlotTime === item.startSlotTime
        ? undefined
        : item
    );
  };

  const adjustBookingSlotsCountView = (
    bookingSlots?: SpecificBookingSlot[]
  ) => {
    if (bookingSlots) {
      const adjustedSlots = [...bookingSlots];
      const remainder = adjustedSlots.length % 4;
      if (remainder !== 0) {
        // Add dummy slots to make the count a multiple of 3
        const dummySlotsCount = 4 - remainder;
        for (let i = 0; i < dummySlotsCount; i++) {
          adjustedSlots.push({
            slotName: "", // Add whatever default values you want for dummy slots
            isAvailable: false,
            startSlotTime: 0,
            endSlotTime: 0,
          });
        }
      }
      return adjustedSlots;
    } else {
      return [];
    }
  };

  const renderItem = (
    item: SpecificBookingSlot,
    index: number,
    isDisabled: boolean
  ) => {
    if (item.slotName) {
      const timeFormat = item.slotName.split(" ")[1];
      const timeSlot = convertTo12HourFormatTimeRange(
        item.slotName.split(" ")[0]
      );

      const isSelected =
        item.startSlotTime === selectedSpecificSlots?.startSlotTime;
      return (
        <div
          key={index}
          className={`grid-item ${
            isSelected ? "selected" : isDisabled ? "disabled" : ""
          }`}
          onClick={() => {
            if (isDisabled) return;
            handlePress(item);
          }}
        >
          {`${timeSlot} ${timeFormat}`}
        </div>
      );
    } else {
      return <div key={index} className={`grid-item-transparent`} />;
    }
  };

  return (
    <div className="grid-container">
      {adjustBookingSlotsCountView(slots?.bookingSlots).map((item, index) =>
        renderItem(item, index, !item.isAvailable)
      )}
    </div>
  );
};

export default TimeSlotList;
