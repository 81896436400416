// RoundedImage.tsx
import React from "react";
import "./RoundedImage.css";

type Props = {
  src: string;
  alt?: string;
  height?: number;
  width?: number;
};

const RoundedImage: React.FC<Props> = ({
  src,
  alt = "Image",
  height = 30,
  width = 30,
}) => {
  return (
    <img
      style={{ height: height, width: width, alignSelf: "center" }}
      className="rounded-image"
      src={src}
      alt={alt}
    />
  );
};

export default RoundedImage;
