/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import TopBar from "../../components/topBar/TopBar";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import "./OrderDescription.css";
import { useFontDesk } from "../../context/frontDeskContext";
import { AiOutlineWarning } from "react-icons/ai";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { Strings } from "../../utils/strings";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderLine, OrderResponse } from "../../apis/types";
import Table from "../../components/table/Table";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import {
  formatAmount,
  formatDateToYYYYMMDD,
  formatUTCToTime,
  getTextColor,
} from "../../utils/utils";
import { isMobile } from "react-device-detect";
import { ItemStatus } from "../../utils/typeValues";

export const OrderDescription = () => {
  const [show, setShow] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { order } = state || {};
  const [modalData, setModalData] = useState<{
    title: string;
    children: React.ReactNode | string;
    btnText?: string;
    btnVariant?: string;
    onClose: () => void;
    onAccept?: () => void;
    headerIcon?: React.ReactNode;
  }>();
  const [loading, setLoading] = useState(false);

  const { onLogOut, onBookPro } = useFontDesk();

  const handleClose = async (isLogout: boolean = false) => {
    setShow(false);
    setModalData(undefined);
  };

  const [orderLines, setOrderLines] = useState<any[]>([]);
  const [orderData, setOrderData] = useState<OrderResponse>();

  useEffect(() => {
    setOrderData(order);
  }, [order]);

  const refetchOrderData = async () => {
    setLoading(true);
    const response = await FrontDeskApi.getOrderById(orderData?.id ?? 0);
    setOrderData(response);
    setLoading(false);
  };

  const handleAfterUpdatingOrder = () => {
    handleClose();
    refetchOrderData();
  };

  const updateStatus = async (orderItemId: number, isCancelItem: boolean) => {
    setLoading(true);
    try {
      const response = isCancelItem
        ? await FrontDeskApi.cancelOrder(orderData?.id ?? 0, orderItemId)
        : await FrontDeskApi.updateOrderStatus(orderData?.id ?? 0, orderItemId);

      if (response.isSuccess) {
        setLoading(false);
        setModalData({
          title: `Item ID: ${orderItemId}`,
          children: isCancelItem
            ? "Item has been cancelled"
            : Strings.ORDER_UPDATED,
          btnText: Strings.OK,
          btnVariant: "outline-success",
          onClose: handleAfterUpdatingOrder,
        });
        setShow(true);
      } else {
        setLoading(false);
        setModalData({
          title: `Item ID: ${orderItemId}`,
          children: isCancelItem
            ? "Cannot cancel item"
            : Strings.ORDER_NOT_UPDATED,
          btnText: Strings.OK,
          btnVariant: "outline-danger",
          onClose: handleAfterUpdatingOrder,
        });
        setShow(true);
      }
    } catch (err) {
      setLoading(false);
      setModalData({
        title: `Item ID: ${orderItemId}`,
        children: isCancelItem
          ? "Error occurred while cancelling item."
          : Strings.ERROR_UPDATING_ORDER,
        btnText: Strings.OK,
        btnVariant: "outline-danger",
        onClose: handleAfterUpdatingOrder,
      });
      setShow(true);
    }
  };

  useEffect(() => {
    if (orderData && orderData?.orderLines.length) {
      const formattedOrderLines = orderData?.orderLines.map(
        (orderLine: any) => ({
          ...orderLine,
          staffDeliveryDate: formatUTCToTime(orderLine.staffDeliveryDate),
          createdDate: formatUTCToTime(orderLine.createdDate),
          courtName: order.courtName,
        })
      );
      setOrderLines(formattedOrderLines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  const columns = [
    { header: "Court Name", accessor: "courtName" },
    { header: "Item ID", accessor: "id" },
    { header: "Item", accessor: "productName" },
    { header: "Quantity", accessor: "quantity" },
    { header: "Status", accessor: "status" },
    { header: "Created At", accessor: "createdDate" },
  ];

  return (
    <>
      <div className="order-desc-container">
        <TopBar
          isBack={isMobile}
          onLogout={() => {
            setModalData({
              title: Strings.LOGOUT,
              children: Strings.LOGOUT_WARNING,
              btnText: Strings.LOGOUT,
              btnVariant: "outline-danger",
              headerIcon: (
                <AiOutlineWarning
                  style={{
                    marginRight: "0.5em",
                    color: "red",
                    alignSelf: "center",
                  }}
                />
              ),
              onClose: () => {
                handleClose(true);
                onLogOut();
              },
            });
            setShow(true);
          }}
          onBookPro={onBookPro}
        />
        {orderLines?.length ? (
          isMobile ? (
            <div style={{ padding: "14px" }}>
              <div style={{ marginBottom: "4px" }}>
                <b className="mob-key-value">Court Name:</b>
                <small className="mob-key-value">{order.courtName}</small>
              </div>
              <div style={{ marginBottom: "4px" }}>
                <b className="mob-key-value">Order Id:</b>
                <small className="mob-key-value">{orderData?.name}</small>
              </div>
              <div style={{ marginBottom: "4px" }}>
                <b className="mob-key-value">Order Date:</b>
                <small className="mob-key-value">
                  {formatDateToYYYYMMDD(
                    new Date(orderData?.orderLines[0].createdDate ?? "")
                  ) +
                    " " +
                    formatUTCToTime(orderData?.orderLines[0].createdDate ?? "")}
                </small>
              </div>
              <div style={{ marginBottom: "4px" }}>
                <b className="mob-key-value">Total Amount:</b>
                <small className="mob-key-value">
                  ${formatAmount(orderData?.amountTotal ?? 0)}
                </small>
              </div>
              <div className="list-group mob-list-container-desc ">
                {orderLines.map((data) => (
                  <div className="list-group-item  mob-item-container">
                    <div className="d-flex w-100 justify-content-between">
                      <div style={{ marginBottom: "4px" }}>
                        <b className="mob-key-value">Item Id:</b>
                        <small className="mob-key-value">{data.id}</small>
                      </div>
                      <div style={{ marginBottom: "4px" }}>
                        <b className="mob-key-value">Qty:</b>
                        <small className="mob-key-value">{data.quantity}</small>
                      </div>
                    </div>
                    <div style={{ marginBottom: "4px" }}>
                      <b className="mob-key-value">Name:</b>
                      <small className="mob-key-value">
                        {data.productName}
                      </small>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <b className="mob-key-value">Status:</b>
                      <small
                        className="mob-key-value"
                        style={{ color: getTextColor(data.status) }}>
                        {data.status}
                      </small>
                    </div>
                    {data.status === ItemStatus.Pending && (
                      <div className="d-flex w-100 justify-content-end">
                        <button
                          type="button"
                          className=" cancelBtn"
                          onClick={() => updateStatus(data.id, true)}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          className=" verifyBtn"
                          onClick={() => updateStatus(data.id, false)}>
                          Deliver
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="order-desc-home-container">
              <p className="order-desc-orders">{Strings.ORDER_ITEMS_LIST}</p>
              <div className="order-desc-innerContainer">
                <Table
                  data={orderLines}
                  columns={columns}
                  onAcceptClicked={(item) => {
                    console.log(item);
                    updateStatus(item.id, false);
                  }}
                  onCancelClicked={(item) => {
                    console.log(item);
                    updateStatus(item.id, true);
                  }}
                  isShowActionButton={true}
                />
              </div>
            </div>
          )
        ) : (
          <p className="order-desc-no-order">{Strings.NO_ORDER_ITEMS_FOUND}</p>
        )}
        <StaticBackdropModal
          show={show}
          handleOpen={modalData?.onAccept ?? modalData?.onClose ?? handleClose}
          handleClose={handleClose}
          title={modalData?.title}
          children={modalData?.children}
          btnText={modalData?.btnText}
          headerIcon={modalData?.headerIcon}
        />
        <LoadingIndicator loading={loading} />
      </div>
    </>
  );
};
