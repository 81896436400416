/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import "./SearchBar.css";
import { debounce } from "../../utils/utils";

type props = {
  list?: any[];
  onSearchCompleted?: (searchValue: string) => void;
  onItemClicked?: (item?: any) => void;
  placeholder?: string;
};

const SearchBar: React.FC<props> = ({
  list,
  onSearchCompleted,
  onItemClicked,
  placeholder,
}) => {
  const [query, setQuery] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      if (onSearchCompleted && searchValue) {
        onSearchCompleted(searchValue);
      }
    }, 1000), // 1 sec debounce delay
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setIsDropdownOpen(value.length > 0);

    // Call the debounced search function
    debouncedSearch(value);
  };

  const handleOptionClick = (value?: any) => {
    onItemClicked?.(value);
    setQuery("");
    setIsDropdownOpen(false);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder={placeholder ?? "Search..."}
      />
      {isDropdownOpen && (list?.length ?? 0) > 0 && (
        <ul className="dropdown">
          {list?.map((option) => (
            <li
              key={option?.userId ?? Math.random().toString()}
              onClick={() => handleOptionClick(option)}
              className="dropdown-item"
            >
              {option?.name ?? ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
