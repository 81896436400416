// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// CHANGE THIS CONFIG WHEN NEW FIREBASE ACCOUNT IS USED
const firebaseConfig = {
  apiKey: "AIzaSyBGKkOHJuXTJ6vSD74HsdlMx3nMwx823o0",
  authDomain: "web-app-push-notificatio-56c26.firebaseapp.com",
  projectId: "web-app-push-notificatio-56c26",
  storageBucket: "web-app-push-notificatio-56c26.appspot.com",
  messagingSenderId: "411698788636",
  appId: "1:411698788636:web:d822f8904dba843a86e2d9",
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

initializeApp(firebaseConfig);
export const deleteFCMToken = () => {
  deleteToken(messaging);
};
export const getMessagingToken = () => {
  return getToken(messaging, {
    // CHANGE THIS KEY WHEN NEW FIREBASE ACCOUNT IS USED
    vapidKey:
      "BKR4J-nMCv-s5R1tGbIQ06QfDErPUhdxw57DHfjkoXZ9EiZJM0tTgZc6C3Dd8XBbr5PVjFJwYLOmsO5Hy6trhA8",
  })
    .then((currentToken) => {
      if (currentToken) {
        // alert(currentToken);
        console.log("current token for client: ", currentToken);
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return null;
    });
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
