/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import TopBar from "../../components/topBar/TopBar";
import "./BookPro.css";
import { isMobile } from "react-device-detect";
import { Strings } from "../../utils/strings";
import { AiOutlineWarning } from "react-icons/ai";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { useFontDesk } from "../../context/frontDeskContext";
import SearchBar from "../../components/searchBar/SearchBar";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import {
  Coach,
  Player,
  SpecificBookingSlot,
  SpecificBookingSlots,
  SpecificBookingSlotsResponse,
} from "../../apis/types";
import { AirDatepickerReact } from "../../components/airDatePicker/AirDatePicker";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import TimeSlotList from "../../components/timeSlotList/TimeSlotList";
import { defaultTimeSlotsData } from "../../utils/data";
import { Coaches } from "../../components/coaches/Coaches";
import { SelectedPlayers } from "../../components/selectedPlayers/SelectedPlayers";
import { useNavigate } from "react-router-dom";

export const BookPro = () => {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<{
    title: string;
    children: React.ReactNode | string;
    btnText?: string;
    btnVariant?: string;
    onClose: () => void;
    onAccept?: () => void;
    headerIcon?: React.ReactNode;
  }>();
  const [loading, setLoading] = useState(false);
  const [enableBookButton, setEnableBookButton] = useState(false);
  const [players, setPlayers] = useState<Player[]>();
  const [selectedPlayer, setSelectedPlayer] = useState<Player[]>([]);
  const [radioValue, setRadioValue] = useState("1");
  const [specificBookingSlots, setSpecificBookingSlots] =
    useState<SpecificBookingSlots>();
  const [bookingCourtType, setBookingCourtType] = useState<number>();
  const [specificBookingSlotsResponse, setSpecificBookingSlotsResponse] =
    useState<SpecificBookingSlotsResponse>();
  const [selectedSpecificSlots, setSelectedSpecificSlots] =
    useState<SpecificBookingSlot>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [coaches, setCoaches] = useState<Coach[]>();
  const [selectedCoach, setSelectedCoach] = useState<Coach>();
  const [playersError, setPlayersError] = useState<string>();
  const navigate = useNavigate();
  const radios = [
    { name: "Indoor", value: "1" },
    { name: "Outdoor", value: "2" },
  ];

  useEffect(() => {
    if (selectedPlayer.length > 0 && selectedCoach) {
      setEnableBookButton(true);
    } else {
      setEnableBookButton(false);
    }
  }, [selectedPlayer, selectedCoach]);

  useEffect(() => {
    const getAllCoaches = async () => {
      try {
        setLoading(true);
        const response = await FrontDeskApi.getCoaches(
          selectedDate ?? "",
          selectedSpecificSlots?.startSlotTime ?? 0,
          selectedSpecificSlots?.endSlotTime ?? 0
        );
        setCoaches(response);
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        setCoaches(undefined);
        setModalData({
          title: Strings.ERROR,
          children: error.title,
          btnText: Strings.OK,
          btnVariant: "outline-danger",
          headerIcon: (
            <AiOutlineWarning
              style={{
                marginRight: "0.5em",
                color: "red",
                alignSelf: "center",
              }}
            />
          ),
          onClose: () => {
            handleClose(true);
          },
        });
        setShow(true);
      }
    };
    if (selectedDate && selectedSpecificSlots) {
      getAllCoaches();
    } else {
      setCoaches(undefined);
    }
  }, [selectedDate, selectedSpecificSlots]);

  // useEffect(() => {
  //   const getSpecificTimeSlots = async () => {
  //     try {
  //       setLoading(true);
  //       // const response = await getSpecificBookingSlots(undefined, selectedDate);
  //       setSpecificBookingSlotsResponse(defaultTimeSlotsData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   };
  //   const resetData = () => {
  //     setSelectedSpecificSlots(undefined);
  //     setSpecificBookingSlots(undefined);
  //   };
  //   if (selectedDate) {
  //     getSpecificTimeSlots();
  //   } else {
  //     resetData();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedDate]);

  useEffect(() => {
    const getSpecificTimeSlots = async () => {
      try {
        setLoading(true);
        if (selectedDate) {
          const response = await FrontDeskApi.getSpecificBookingSlots(
            1,
            selectedDate
          );
          setSpecificBookingSlotsResponse(response);
        }
      } catch (error) {
        console.error("Error fetching specific booking slots:", error);
      } finally {
        setLoading(false);
      }
    };

    const resetData = () => {
      setSelectedSpecificSlots(undefined);
      setSpecificBookingSlots(undefined);
    };
    console.log("Selected Date Changed", selectedDate);
    if (selectedDate) {
      getSpecificTimeSlots();
    } else {
      resetData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, bookingCourtType]);

  useEffect(() => {
    if (radioValue === "1") {
      setSelectedSpecificSlots(undefined);
      setSpecificBookingSlots(
        specificBookingSlotsResponse?.availableIndoorSlots
      );
    } else if (radioValue === "2") {
      setSelectedSpecificSlots(undefined);
      setSpecificBookingSlots(
        specificBookingSlotsResponse?.availableOutdoorSlots
      );
    } else if (radioValue === "3") {
      setSelectedSpecificSlots(undefined);
      setSpecificBookingSlots(
        specificBookingSlotsResponse?.availableSingleSlots
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioValue, specificBookingSlotsResponse]);

  const { onLogOut } = useFontDesk();

  const handleClose = async (isLogout: boolean = false) => {
    setShow(false);
    setModalData(undefined);
  };
  const bookPro = async () => {
    setLoading(true);
    FrontDeskApi.bookPro({
      bookingDate: selectedDate ?? "",
      startSlotTime: selectedSpecificSlots?.startSlotTime ?? 0,
      endSlotTime: selectedSpecificSlots?.endSlotTime ?? 0,
      courtType: Number(radioValue),
      gender: 1,
      filterByLevel: 1,
      playerIds: selectedPlayer.map((el) => el.userId),
      coachId: selectedCoach?.id ?? 0,
    })
      .then((response) => {
        let title = "";
        if (response.isSuccess) {
          title = Strings.SUCCESS;
        } else {
          title = Strings.ERROR;
        }
        setModalData({
          title: title,
          children: response.message,
          btnText: Strings.OK,
          onClose: () => {
            handleClose(true);
            navigate(-1);
          },
        });
        setShow(true);
      })
      .catch((error) => {
        console.log("Ayush", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getPlayersByFilteredName = (filterName: string, pageNum: number) => {
    setLoading(true);
    setPlayersError(undefined);
    FrontDeskApi.getplayersBySearch(filterName, pageNum)
      .then((response) => {
        console.log("selectedPlayer", selectedPlayer.length);
        // const filteredUsers = response.filter((el) => {
        //   return !selectedPlayer.filter((f) => {
        //     return f.userId === el.userId;
        //   }).length;
        // });
        setPlayers(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
        setLoading(false);
        setPlayers(undefined);
        setPlayersError("No players found.");
      });
  };

  const containerClassName = useMemo(() => {
    return !selectedPlayer ? "book-pro-container-full" : "book-pro-container";
  }, [selectedPlayer]);
  const pageNum = 0;
  return (
    <div className={"book-pro-container-full"}>
      <TopBar
        isBack={isMobile}
        onLogout={() => {
          setModalData({
            title: Strings.LOGOUT,
            children: Strings.LOGOUT_WARNING,
            btnText: Strings.LOGOUT,
            btnVariant: "outline-danger",
            headerIcon: (
              <AiOutlineWarning
                style={{
                  marginRight: "0.5em",
                  color: "red",
                  alignSelf: "center",
                }}
              />
            ),
            onClose: () => {
              handleClose(true);
              onLogOut();
            },
          });
          setShow(true);
        }}
      />
      <h3
        style={{
          color: "#954435",
          fontSize: 25,
          marginTop: 20,
          marginLeft: 20,
        }}
      >
        Book pro for customer
      </h3>
      <div className="book-pro_info-container">
        <span className="book-pro_info">{Strings.BOOK_PRO_INFO}</span>
      </div>
      <SearchBar
        list={
          players
            ? players.filter((el) => {
                return !selectedPlayer.filter((f) => {
                  return f.userId === el.userId;
                }).length;
              })
            : undefined
        }
        onSearchCompleted={(value) => getPlayersByFilteredName(value, pageNum)}
        onItemClicked={(player) => {
          if (selectedPlayer.length > 3) {
            setModalData({
              title: Strings.ERROR,
              children: "Only four Players are allowed",
              btnText: Strings.OK,
              onClose: () => {
                handleClose(true);
              },
            });
            setShow(true);
            return;
          }
          setSelectedPlayer([...selectedPlayer, player]);
          setPlayers(undefined);
        }}
        placeholder={Strings.ENTER_CUSTOMER_NAME}
      />
      {playersError ? (
        <p style={{ textAlign: "center", fontSize: 13, color: "red" }}>
          {playersError}
        </p>
      ) : null}

      <SelectedPlayers
        players={selectedPlayer ?? []}
        removePlayer={(player) => {
          setSelectedPlayer((l) =>
            l.filter((item) => item.userId !== player.userId)
          );
        }}
      />
      <div className="airDatePicker-container">
        <div className="airDatePicker-inner-container ">
          <p className="date-info">{Strings.BOOK_PRO_TIME_INFO}</p>
          <AirDatepickerReact
            startDate={new Date()}
            // endDate={coachAvailabilityWeekRanges.currentWeekEnd}
            isThisWeek={true}
            // preSelectedDates={currentWeekScheduledDates}
            // viewDate={coachAvailabilityWeekRanges.currentWeekStart}
            onSelectDate={(date, formattedDate) => {
              setSelectedDate(formattedDate as string);
            }}
            handleOnAllAvailableSelected={async () => {}}
            handleOnAllNotAvailableSelected={async () => {}}
          />
        </div>
      </div>
      <div className="courttabs-container">
        <ButtonGroup className="button-group-full-width">
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={
                radioValue === radio.value
                  ? "outline-success"
                  : "outline-secondary"
              }
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
              className="toggle-button-full-width"
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
      {specificBookingSlots ? (
        <TimeSlotList
          setSelectedSpecificSlots={setSelectedSpecificSlots}
          selectedSpecificSlots={selectedSpecificSlots}
          setModalData={setModalData}
          setShow={setShow}
          slots={specificBookingSlots}
        />
      ) : null}
      {coaches && (
        <Coaches
          coaches={coaches}
          setSelectedCoach={setSelectedCoach}
          selectedCoach={selectedCoach}
        />
      )}
      {specificBookingSlots && (
        <div
          style={{
            marginTop: isMobile ? 0 : 30,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <button
            type="button"
            className={
              enableBookButton
                ? "btn book-coach-Btn"
                : " book-coach-Btn-disable"
            }
            onClick={() => {
              if (enableBookButton) {
                bookPro();
              }
            }}
          >
            {Strings.BOOK_COACH}
          </button>
        </div>
      )}
      <StaticBackdropModal
        show={show}
        handleOpen={modalData?.onAccept ?? modalData?.onClose ?? handleClose}
        handleClose={handleClose}
        title={modalData?.title}
        children={modalData?.children}
        btnText={modalData?.btnText}
        headerIcon={modalData?.headerIcon}
      />
      <LoadingIndicator loading={loading} />
    </div>
  );
};
