/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import en from "air-datepicker/locale/en";
import "./AirDatepickerStyles.css";

type dateProps = {
  startDate?: Date;
  endDate?: Date;
  onSelectDate: (date: Date | Date[], formattedDate: string | string[]) => void;
  isThisWeek?: boolean;
  handleOnAllAvailableSelected: () => void;
  handleOnAllNotAvailableSelected: () => void;
  preSelectedDates?: string[];
  viewDate?: Date;
};

export const AirDatepickerReact: React.FC<dateProps> = ({
  startDate,
  endDate,
  onSelectDate,
  isThisWeek,
  handleOnAllAvailableSelected,
  handleOnAllNotAvailableSelected,
  preSelectedDates,
  viewDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const inputRef = useRef(null);
  const dpRef = useRef<AirDatepicker>();

  const allWeekAvailable = {
    content: "Available ✓",
    className: "all-week-available",
    onClick: (dp: {
      selectDate: (arg0: Date) => void;
      setViewDate: (arg0: Date) => void;
    }) => {
      handleOnAllAvailableSelected();
    },
  };

  const allWeekNotAvailable = {
    content: "Unavailable ✗",
    className: "all-week-not-available",
    onClick: (dp: {
      selectDate: (arg0: Date) => void;
      setViewDate: (arg0: Date) => void;
    }) => {
      handleOnAllNotAvailableSelected();
    },
  };

  useEffect(() => {
    if (inputRef.current) {
      dpRef.current = new AirDatepicker(inputRef.current, {
        inline: true,
        locale: en,
        multipleDates: false, // Allow only one date to be selected
        minDate: startDate,
        maxDate: endDate,
        disableNavWhenOutOfRange: true,
        selectedDates: selectedDate ? [selectedDate] : [],
        dateFormat: "yyyy-MM-dd",
        navTitles: {
          days: "<span style='color: #0A4D43; font-weight: 600; pointer-events: none;'>MMMM yyyy</span>",
        },
        onRenderCell({ date, cellType }) {
          if (cellType === "day") {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (
              startDate &&
              endDate &&
              (date < startDate || date > endDate) &&
              date.getTime() !== today.getTime()
            ) {
              return {
                disabled: true,
                classes: "-disabled-",
              };
            }
          }
        },
        onSelect({ date, formattedDate }) {
          // Set the selected date in state
          if (typeof formattedDate === "string") {
            setSelectedDate(formattedDate);
            onSelectDate(date, formattedDate.replaceAll("/", "-"));
          }
        },
      });

      if (viewDate) {
        dpRef.current.setViewDate(viewDate);
      }
    }

    return () => {
      if (dpRef.current) {
        dpRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, preSelectedDates, viewDate]);

  return <div ref={inputRef} />;
};
