import { CoachAvailabilityResponse } from "../apis/types";
import { DateGroup } from "../types/types";
import { ItemStatus } from "./typeValues";

export const incrementDateByOneDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + 1);
  return newDate;
};

export const getStartOfWeek = (date: Date): Date => {
  const start = new Date(date);
  const day = start.getDay();
  const diff = start.getDate() - day; // adjust when day is not Sunday
  return new Date(start.setDate(diff));
};

export const getEndOfWeek = (date: Date): Date => {
  const startOfWeek = getStartOfWeek(date);
  return new Date(startOfWeek.setDate(startOfWeek.getDate() + 7));
};

export const getNextWeekRange = (date: Date): { start: Date; end: Date } => {
  const startOfWeek = getStartOfWeek(date);
  const nextWeekStart = new Date(
    startOfWeek.setDate(startOfWeek.getDate() + 7)
  );
  const nextWeekEnd = new Date(nextWeekStart);
  nextWeekEnd.setDate(nextWeekStart.getDate() + 7);
  return { start: nextWeekStart, end: nextWeekEnd };
};

export const getAfterNextWeekRange = (
  date: Date
): { start: Date; end: Date } => {
  const { start: nextWeekStart } = getNextWeekRange(date);
  const afterNextWeekStart = new Date(
    nextWeekStart.setDate(nextWeekStart.getDate() + 7)
  );
  const afterNextWeekEnd = new Date(afterNextWeekStart);
  afterNextWeekEnd.setDate(afterNextWeekStart.getDate() + 7);
  return { start: afterNextWeekStart, end: afterNextWeekEnd };
};

export const isValidMobileNumber = (mobileNumber: string): boolean => {
  // Regular expression to match 10-digit mobile numbers starting with 7, 8, or 9
  const mobileNumberRegex = /^[789]\d{9}$/;
  return mobileNumberRegex.test(mobileNumber);
};

export const addOneHour = (timeStr: string): string => {
  // Parse the input time string to a Date object
  const [time, period] = timeStr.split(" ");
  const [hoursStr, minutesStr] = time.split(":");
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a new Date object
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // Add one hour to the time
  date.setHours(date.getHours() + 1);

  // Get the new hours and minutes
  let newHours = date.getHours();
  const newMinutes = date.getMinutes();
  let newPeriod = "AM";

  if (newHours >= 12) {
    newPeriod = "PM";
    if (newHours > 12) {
      newHours -= 12;
    }
  }
  if (newHours === 0) {
    newHours = 12;
  }

  // Format the hours and minutes to a string
  const formattedHours = newHours.toString().padStart(2, "0");
  const formattedMinutes = newMinutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${newPeriod}`;
};

export const addMinutesToTime = (
  timeStr: string,
  minutesToAdd: number
): string => {
  // Parse the input time string to a Date object
  const [time, period] = timeStr.split(" ");
  const [hoursStr, minutesStr] = time.split(":");
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a new Date object
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  // Add one hour to the time
  date.setMinutes(date.getMinutes() + minutesToAdd);

  // Get the new hours and minutes
  let newHours = date.getHours();
  const newMinutes = date.getMinutes();
  let newPeriod = "AM";

  if (newHours >= 12) {
    newPeriod = "PM";
    if (newHours > 12) {
      newHours -= 12;
    }
  }
  if (newHours === 0) {
    newHours = 12;
  }

  // Format the hours and minutes to a string
  const formattedHours = newHours.toString().padStart(2, "0");
  const formattedMinutes = newMinutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${newPeriod}`;
};

export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so we add 1
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatUTCToTime = (timeStamp: string) => {
  const date = new Date(timeStamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert hour from 24-hour to 12-hour format

  const formattedTime = `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return formattedTime;
};

export const groupDatesByMonthAndYear = (dates: string[]) => {
  return dates.reduce((acc: DateGroup, dateStr: string) => {
    const date = new Date(dateStr);
    const key = getDateAndYearKey(date);

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(dateStr);

    return acc;
  }, {});
};

export const getDateAndYearKey = (date: Date) =>
  `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`;

export const filteredDatesByWeek = (
  dateGroup: DateGroup,
  weekStartDate: Date,
  weekEndDate: Date
) =>
  Object.entries(dateGroup).reduce((acc: DateGroup, [key, dateArray]) => {
    const filteredDates = dateArray.filter((dateStr) => {
      const date = new Date(dateStr);
      return date >= weekStartDate && date <= weekEndDate;
    });

    if (filteredDates.length > 0) {
      acc[key] = filteredDates;
    }

    return acc;
  }, {});

export const getDatesFromDateGroup = (dates: string[][]) => {
  return Array.from(new Set(dates.flat()));
};

export const convertTo12HourFormat = (timeNumber: string): string => {
  // Convert to string to handle cases where timeNumber is a single digit
  const timeStr = timeNumber.toString().padStart(2, "0");

  // Parse hours and minutes
  const hours = parseInt(timeStr.substring(0, 2));
  const minutes = parseInt(timeStr.substring(2));

  // Determine AM/PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hours % 12 === 0 ? 12 : hours % 12;

  // Format hours and minutes
  const hoursStr = hours12.toString().padStart(2, "0");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const minutesStr = minutes.toString().padStart(2, "0");

  // Return formatted time
  return `${hoursStr}:00 ${period}`;
};

export const requestNotificationPermission = (
  callback: () => void,
  setNotificationPermission: (
    value: React.SetStateAction<NotificationPermission | undefined>
  ) => void
) => {
  if (!("Notification" in window)) {
    alert("This browser does not support notifications.");
    callback();
  } else if (Notification.permission === "granted") {
    alert("You have already granted permission for notifications.");
    callback();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      setNotificationPermission(permission);
      callback();
    });
  } else {
    alert(
      "You have denied permission for notifications. Please enable it in your browser settings."
    );
    callback();
  }
};

export const sendNotification = (title: string, options: any) => {
  if (Notification.permission === "granted") {
    const notification = new Notification(title, options);
    notification.onclick = () => {
      window.focus();
      notification.close();
    };
  } else {
    console.log("Permission to send notifications is not granted");
  }
};

export const getTextColor = (status: string) => {
  return status === ItemStatus.Delivered
    ? "green"
    : status === ItemStatus.Cancelled
    ? "red"
    : "black";
};

export const formatAmount = (num: number): string => {
  return num.toFixed(2); // Always formats to two decimal places
};

// Helper function to pad single digit numbers with leading zeros
export const padWithZero = (num: number): string => {
  return num < 10 ? `0${num}` : num.toString();
};

// Function to update the timer display
export const updateTimer = (startTime: Date) => {
  const now = new Date();
  const elapsedTime = now.getTime() - startTime.getTime(); // Calculate elapsed time in milliseconds

  // Convert elapsed time to hours, minutes, seconds
  const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
  const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

  // Format hours, minutes, seconds with leading zeros if necessary
  const formattedTime = `${padWithZero(hours)}:${padWithZero(
    minutes
  )}:${padWithZero(seconds)}`;
  return formattedTime;
};

// Function to start the timer and return current formatted time
export const startTimer = (createdTime: string): (() => string) => {
  const startTime = new Date(createdTime);

  // Initial call to updateTimer to immediately display the initial time
  updateTimer(startTime);

  // Update the timer every second (1000 milliseconds)
  setInterval(() => {
    updateTimer(startTime); // Update the timer but don't need to store the result
  }, 1000);

  // Return a function to retrieve the current formatted time
  return () => updateTimer(startTime);
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number
) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const convertTo12HourFormatTimeRange = (timeRange: string): string => {
  const [startTime, endTime] = timeRange.split("-");
  const startParts = startTime.trim().split(":");
  const endParts = endTime.trim().split(":");

  let startHour = parseInt(startParts[0], 10);
  let endHour = parseInt(endParts[0], 10);
  if (startHour >= 12) {
    startHour -= 12;
  }
  if (endHour >= 12) {
    endHour -= 12;
  }
  if (startHour === 0) {
    startHour = 12;
  }
  if (endHour === 0) {
    endHour = 12;
  }

  const formattedStartTime = `${startHour.toString().padStart(2, "0")}:${
    startParts[1]
  }`;
  const formattedEndTime = `${endHour.toString().padStart(2, "0")}:${
    endParts[1]
  }`;

  return `${formattedStartTime}-${formattedEndTime}`;
};

export const groupSlotsByDate = (
  slots: CoachAvailabilityResponse[]
): { [key: string]: CoachAvailabilityResponse[] } => {
  return slots.reduce((acc, slot) => {
    // If the date is not already a key in the accumulator, initialize it
    if (!acc[slot.date]) {
      acc[slot.date] = [];
    }
    // Push the slot into the corresponding date array
    acc[slot.date].push(slot);
    return acc;
  }, {} as { [key: string]: CoachAvailabilityResponse[] });
};
