import axios from "axios";
import { ACCESS_TOKEN } from "../constants";
import _ from "lodash";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://test-ultra-middleware.azurewebsites.net",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config: { headers: any }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  (config.headers || {}).Authorization = `Bearer ${accessToken}`;
  return config;
});

api.interceptors.response.use(
  (response: any) => response,
  async (error: { response: { status: number } }) => {
    if (error.response.status === 401) {
      localStorage.removeItem(ACCESS_TOKEN);
    }

    const errorTitle = _.get(error, "response.data.title", "");

    // if (!_.isEmpty(errorTitle)) {
    //   toast.error(errorTitle);
    // }

    return Promise.reject(_.get(error, "response.data", {}));
  }
);

export default api;
