import React, { useEffect, useState } from "react";
import TopBar from "../../components/topBar/TopBar";

import {
  filteredDatesByWeek,
  formatDateToYYYYMMDD,
  getDatesFromDateGroup,
  groupDatesByMonthAndYear,
  incrementDateByOneDay,
} from "../../utils/utils";

import { isMobile } from "react-device-detect";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import "./CoachAvailability.css";
import TimeSlotList from "../../components/coachTimeSlotsList/TimeSlotList";
import { useFontDesk } from "../../context/frontDeskContext";
import { AiOutlineWarning } from "react-icons/ai";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import { Strings } from "../../utils/strings";
import { AirDatepickerReact } from "../../components/coachAirDatePicker/airDatePicker";

export const CoachAvailability = () => {
  const { coachAvailabilityWeekRanges } = useFontDesk();

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<{
    title: string;
    children: React.ReactNode | string;
    btnText?: string;
    btnVariant?: string;
    onClose: () => void;
    headerIcon?: React.ReactNode;
  }>();
  const [loading, setLoading] = useState(false);

  const { onLogOut } = useFontDesk();

  const handleClose = async (isLogout: boolean = false) => {
    setShow(false);
    setModalData(undefined);
    if (!isLogout) {
      await getAllAvailableTimeSlots();
    }
  };
  const [currentWeekScheduledDates, setCurrentWeekScheduledDates] = useState<
    string[]
  >([]);
  const [nextWeekScheduledDates, setNextWeekScheduledDates] = useState<
    string[]
  >([]);
  const [afterNextWeekScheduledDates, setAfterNextWeekScheduledDates] =
    useState<string[]>([]);

  const handleShow = (date?: string) => {
    setModalData({
      title: `${Strings.SELECT_TIME_SLOTS} ${date}`,
      children: <TimeSlotList date={date ?? ""} setLoading={setLoading} />,
      onClose: () => handleClose(),
    });
    setShow(true);
  };

  const getAllAvailableTimeSlots = async () => {
    try {
      setLoading(true);

      const response = await FrontDeskApi.getCoachAvailabilities(
        formatDateToYYYYMMDD(coachAvailabilityWeekRanges.currentWeekStart),
        formatDateToYYYYMMDD(coachAvailabilityWeekRanges.afterNextWeekEnd)
      );
      const dates = response.map((item) => item.date.split(" ")[0]);
      const groupedDates = groupDatesByMonthAndYear(dates);

      const currentWeekDateGroup = filteredDatesByWeek(
        groupedDates,
        coachAvailabilityWeekRanges.currentWeekStart,
        coachAvailabilityWeekRanges.currentWeekEnd
      );

      const nextWeekDateGroup = filteredDatesByWeek(
        groupedDates,
        coachAvailabilityWeekRanges.nextWeekStart,
        coachAvailabilityWeekRanges.nextWeekEnd
      );

      const afterNextWeekDateGroup = filteredDatesByWeek(
        groupedDates,
        coachAvailabilityWeekRanges.afterNextWeekStart,
        coachAvailabilityWeekRanges.afterNextWeekEnd
      );

      setCurrentWeekScheduledDates(
        getDatesFromDateGroup(Object.values(currentWeekDateGroup))
      );
      setNextWeekScheduledDates(
        getDatesFromDateGroup(Object.values(nextWeekDateGroup))
      );
      setAfterNextWeekScheduledDates(
        getDatesFromDateGroup(Object.values(afterNextWeekDateGroup))
      );

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setCurrentWeekScheduledDates([]);
      setNextWeekScheduledDates([]);
      setAfterNextWeekScheduledDates([]);
    }
  };

  useEffect(() => {
    getAllAvailableTimeSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateClicked = (
    formattedDate: string | string[],
    isDateSelected: boolean
  ) => {
    if (isDateSelected) {
      if (typeof formattedDate === "string") {
        handleShow(formattedDate);
      } else {
        handleShow(formattedDate[formattedDate.length - 1]);
      }
    }
  };

  const handleAllDatesSelected = async (
    startDate: Date,
    endDate: Date,
    isAvailableForAllDays: boolean
  ) => {
    setLoading(true);
    try {
      const today = new Date();
      let updatedStartDate: Date;

      if (today.getDate() === startDate.getDate()) {
        updatedStartDate = startDate;
      } else {
        updatedStartDate = incrementDateByOneDay(startDate);
      }

      await FrontDeskApi.postCoachAvailabilityForAllDays({
        startDate: formatDateToYYYYMMDD(updatedStartDate),
        endDate: formatDateToYYYYMMDD(endDate),
        isAvailableForAllTimeSlots: isAvailableForAllDays,
      });
      await getAllAvailableTimeSlots();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="coach-outer-container">
      <TopBar
        isBack={isMobile}
        onLogout={() => {
          setModalData({
            title: Strings.LOGOUT,
            children: Strings.LOGOUT_WARNING,
            btnText: Strings.LOGOUT,
            btnVariant: "outline-danger",
            headerIcon: (
              <AiOutlineWarning
                style={{
                  marginRight: "0.5em",
                  color: "red",
                  alignSelf: "center",
                }}
              />
            ),
            onClose: () => {
              handleClose(true);
              onLogOut();
            },
          });
          setShow(true);
        }}
      />
      <div className="coach-container">
        <div className="coach-innerContainer">
          <p className="coach-this-week">{Strings.THIS_WEEK}</p>
          <AirDatepickerReact
            startDate={coachAvailabilityWeekRanges.currentWeekStart}
            endDate={coachAvailabilityWeekRanges.currentWeekEnd}
            isThisWeek={true}
            preSelectedDates={currentWeekScheduledDates}
            viewDate={coachAvailabilityWeekRanges.currentWeekStart}
            onSelectDate={(date, formattedDate, isDateSelected) => {
              handleDateClicked(formattedDate, isDateSelected);
            }}
            handleOnAllAvailableSelected={async () => {
              await handleAllDatesSelected(
                coachAvailabilityWeekRanges.currentWeekStart,
                coachAvailabilityWeekRanges.currentWeekEnd,
                true
              );
            }}
            handleOnAllNotAvailableSelected={async () => {
              await handleAllDatesSelected(
                coachAvailabilityWeekRanges.currentWeekStart,
                coachAvailabilityWeekRanges.currentWeekEnd,
                false
              );
            }}
          />
        </div>
        <div className="coach-innerContainer">
          <p className="coach-this-week">{Strings.NEXT_WEEK}</p>
          <AirDatepickerReact
            startDate={coachAvailabilityWeekRanges.nextWeekStart}
            endDate={coachAvailabilityWeekRanges.nextWeekEnd}
            preSelectedDates={nextWeekScheduledDates}
            viewDate={coachAvailabilityWeekRanges.nextWeekStart}
            onSelectDate={(date, formattedDate, isDateSelected) => {
              handleDateClicked(formattedDate, isDateSelected);
            }}
            handleOnAllAvailableSelected={async () => {
              await handleAllDatesSelected(
                coachAvailabilityWeekRanges.nextWeekStart,
                coachAvailabilityWeekRanges.nextWeekEnd,
                true
              );
            }}
            handleOnAllNotAvailableSelected={async () => {
              await handleAllDatesSelected(
                coachAvailabilityWeekRanges.nextWeekStart,
                coachAvailabilityWeekRanges.nextWeekEnd,
                false
              );
            }}
          />
        </div>
        <div className="coach-innerContainer">
          <p className="coach-this-week">{Strings.AFTER_NEXT_WEEK}</p>
          <AirDatepickerReact
            startDate={coachAvailabilityWeekRanges.afterNextWeekStart}
            endDate={coachAvailabilityWeekRanges.afterNextWeekEnd}
            preSelectedDates={afterNextWeekScheduledDates}
            viewDate={coachAvailabilityWeekRanges.afterNextWeekStart}
            onSelectDate={(date, formattedDate, isDateSelected) => {
              handleDateClicked(formattedDate, isDateSelected);
            }}
            handleOnAllAvailableSelected={async () => {
              await handleAllDatesSelected(
                coachAvailabilityWeekRanges.afterNextWeekStart,
                coachAvailabilityWeekRanges.afterNextWeekEnd,
                true
              );
            }}
            handleOnAllNotAvailableSelected={async () => {
              await handleAllDatesSelected(
                coachAvailabilityWeekRanges.afterNextWeekStart,
                coachAvailabilityWeekRanges.afterNextWeekEnd,
                false
              );
            }}
          />
        </div>
        <StaticBackdropModal
          show={show}
          handleOpen={modalData?.onClose ?? handleClose}
          handleClose={handleClose}
          title={modalData?.title}
          children={modalData?.children}
          btnText={modalData?.btnText}
          headerIcon={modalData?.headerIcon}
        />
        <LoadingIndicator loading={loading} />
      </div>
    </div>
  );
};
