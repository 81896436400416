import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useFontDesk } from "../context/frontDeskContext";
import { AdminUser, CoachUser } from "../utils/constants";

const PrivateRoute = ({ children }: any) => {
  const { isAuthenticated, staffModel } = useFontDesk();
  const { pathname } = useLocation();

  if (isAuthenticated === null) {
    return <Navigate to={`${pathname}`} />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/otp-login" />;
  }

  if (staffModel?.role === CoachUser.value) {
    if (CoachUser.paths.includes(pathname)) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  }

  if (staffModel?.role === AdminUser.value) {
    if (AdminUser.paths.includes(pathname)) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  }
};

export default PrivateRoute;
