import React from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineInfoCircle } from "react-icons/ai"; // Example: using AiOutlineInfoCircle from react-icons
import "./StaticBackdropModal.css";

type props = {
  show: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
  children?: React.ReactNode | string;
  title?: string;
  btnText?: string;
  headerIcon?: React.ReactNode;
};

export const StaticBackdropModal: React.FC<props> = ({
  title,
  show,
  handleClose,
  handleOpen,
  children,
  btnText,
  headerIcon = (
    <AiOutlineInfoCircle
      style={{ marginRight: "0.5em", alignSelf: "center" }}
    />
  ),
}) => {
  return (
    <Modal
      container={document.body}
      show={show}
      onHide={handleClose ?? handleOpen}
      backdrop="static"
      centered
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 15 }}>
          {headerIcon}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn custom-button"
          onClick={handleOpen ?? handleClose}
        >
          {btnText ?? "Done"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
