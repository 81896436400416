import React from "react";
import "./Coaches.css";

type props = {
  isAvailable: boolean;
  feesPerHour: number;
};

export const Coachesfooter: React.FC<props> = ({
  isAvailable,
  feesPerHour,
}) => {
  return (
    <div
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        marginTop: 20,
      }}>
      <div
        style={{
          display: "flex",
          alignContent: "center",
        }}>
        <div className="radio-button-inner" />
        <span
          style={{
            marginLeft: 5,
            textAlign: "center",
            fontSize: 10,
            color: isAvailable ? "#0A4D43" : "red",
          }}>
          {isAvailable ? "Available" : "Unavailable"}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}>
        <span style={{ fontSize: 10, color: "grey" }}>{`Per hour: `}</span>
        <span
          style={{
            fontSize: 14,
            color: "#0a4d43",
            fontWeight: "600",
          }}>
          {` $${feesPerHour}`}
        </span>
      </div>
    </div>
  );
};
