/* eslint-disable @typescript-eslint/no-unused-vars */
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../screen/Login/Login";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Home } from "../screen/Home/Home";
import { OrderDescription } from "../screen/OrderDescription/OrderDescription";
import { BookPro } from "../screen/BookPro/BookPro";
import { CoachAvailability } from "../screen/CoachAvailability/CoachAvailability";
import { GetStarted } from "../screen/GetStarted/GetStarted";
import { CoachPlans } from "../screen/CoachPlans/CoachPlans";

const RootRouter = () => {
  return (
    <Routes>
      <Route
        path="/otp-login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path=""
        element={
          <PrivateRoute>
            <GetStarted />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/order-description"
        element={
          <PrivateRoute>
            <OrderDescription />
          </PrivateRoute>
        }
      />
      <Route
        path="/book-pro"
        element={
          <PrivateRoute>
            <BookPro />
          </PrivateRoute>
        }
      />
      <Route
        path="/coach-availability"
        element={
          <PrivateRoute>
            <CoachAvailability />
          </PrivateRoute>
        }
      />
      <Route
        path="/coach-plans"
        element={
          <PrivateRoute>
            <CoachPlans />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default RootRouter;
