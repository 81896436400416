/* eslint-disable @typescript-eslint/no-unused-vars */
import { MouseEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import { ACCESS_TOKEN } from "../../constants";
import { useFontDesk } from "../../context/frontDeskContext";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ReactCodeInput from "react-verification-code-input";
import styled from "styled-components";
import Logo from "../../assets/logo.png";
import "./Login.css";
import { Form, FormControl, Image } from "react-bootstrap";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import { AiOutlineCheckCircle, AiOutlineWarning } from "react-icons/ai";
import { Strings } from "../../utils/strings";
import {
  formatInternationalPhoneNumber,
  validatePhoneNumber,
} from "../../utils/validations";
import { requestNotificationPermission } from "../../utils/utils";
import * as EmailValidator from "email-validator";

const OtpCodeInput = styled(ReactCodeInput)((props: any) => ({
  minWidth: 340,
  display: "flex",
  justifyContent: "center",
  input: {
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: -0.1,
    color: "#111",
    borderRadius: 8,
    border: "1.5px solid rgba(17, 17, 17, 0.06)",
    marginLeft: 4,
    marginRight: 4,
    fontFamily: "inherit",

    "&:first-child": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      marginLeft: 0,
    },

    "&:last-child": {
      borderRight: "1.5px solid rgba(17, 17, 17, 0.06)",
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      marginRight: 0,
    },

    "&:focus": {
      border: "1.5px solid #954435",
      caretColor: "#954435",

      "& + input": {
        borderLeft: "1.5px solid rgba(17, 17, 17, 0.06)",
      },
    },
  },
}));

const LoginPage = () => {
  const [timerKey, setTimerKey] = useState(new Date().getTime());
  const [sendClicked, setSendClicked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [otpCode, setOtpCode] = useState<string>("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableRetry, setDisableRetry] = useState(true);
  const [modalData, setModalData] = useState<{
    title: string;
    children: string;
    headerIcon?: React.ReactNode;
    btnText?: string;
    onClose?: () => void;
    onAccept?: () => void;
  }>();
  const { fetchMe } = useFontDesk();
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input element

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // const handleChange = (event: {
  //   target: { value: SetStateAction<string | undefined> };
  // }) => {
  //   let value = event.target?.value?.toString();
  //   if (!value?.startsWith("+")) {
  //     value = `+1${value}`;
  //   }
  //   setIsValid(true);
  //   value = formatInternationalPhoneNumber(value);
  //   setEmail(value);
  // };

  const handleEmailChange = (event: {
    target: { value: SetStateAction<string | undefined> };
  }) => {
    let value = event.target?.value?.toString();
    setIsValid(true);
    setEmail(value);
  };

  const handleCloseModal = (isLoginSuccess: boolean = false) => {
    if (
      modalData?.children === Strings.OTP_VRIFICATION_DONE ||
      isLoginSuccess
    ) {
      fetchMe();
    }
    setModalData(undefined);
  };

  const handleClose = () => {
    setModalData(undefined);
  };

  const {
    iosInstructions,
    setIosInstructions,
    notificationPermission,
    setNotificationPermission,
  } = useFontDesk();

  useEffect(() => {
    if (notificationPermission === "granted") {
      // do nothing for now. This case is handled in home screen
    } else if (notificationPermission === "denied") {
      alert(Strings.NOTIFICATION_PERMISSION_DENIED);
    } else {
      setModalData({
        title: Strings.NOTIFICATION_PERMISSION,
        children: Strings.NOTIFICATION_PERMISSION_REQUIRED,
        btnText: Strings.ACCEPT,
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
        onClose: handleClose,
        onAccept: () =>
          requestNotificationPermission(handleClose, setNotificationPermission),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPermission]);

  useEffect(() => {
    if (iosInstructions) {
      setModalData({
        title: Strings.ENABLE_NOTIFICATION,
        children: Strings.IOS_PERMISSION,
        btnText: "Close",
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
        onClose: () => {
          setIosInstructions(false);
        },
        onAccept: undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iosInstructions]);

  const sendOTPCode = async () => {
    if (email && EmailValidator.validate(email)) {
      try {
        setLoading(true);
        const response = await FrontDeskApi.otpCodeSend({
          email: `${email}`,
        });
        setIsValid(true);
        setSendClicked(true);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setModalData({
          title: Strings.ERROR_SENDING_OTP,
          children: error.message,
          onClose: undefined,
          onAccept: undefined,
          headerIcon: (
            <AiOutlineWarning
              style={{
                marginRight: "0.5em",
                color: "red",
                alignSelf: "center",
              }}
            />
          ),
        });
      }
    } else {
      setIsValid(false);
      setModalData({
        title: Strings.INVALID,
        children: Strings.PLEASE_ENTER_VALID_NUMBER,
        onClose: undefined,
        onAccept: undefined,
        headerIcon: (
          <AiOutlineWarning
            style={{ marginRight: "0.5em", color: "red", alignSelf: "center" }}
          />
        ),
      });
    }
  };

  const handleVerifyOtp = async () => {
    if (otpCode?.length === 4) {
      try {
        setLoading(true);
        const response = await FrontDeskApi.otpCodeVerify({
          email: email ?? "",
          otp: otpCode,
        });
        localStorage.setItem(ACCESS_TOKEN, response.jwt ?? "");
        setLoading(false);
        handleCloseModal(true);
      } catch (error: any) {
        setLoading(false);
        setModalData({
          title: Strings.ERROR_OTP_VERIFICATION,
          children: error.message,
          onClose: undefined,
          onAccept: undefined,
          headerIcon: (
            <AiOutlineWarning
              style={{
                marginRight: "0.5em",
                color: "red",
                alignSelf: "center",
              }}
            />
          ),
        });
      }
    } else {
      setModalData({
        title: Strings.INVALID,
        children: Strings.ENTER_VALID_OTP,
        onClose: undefined,
        onAccept: undefined,
        headerIcon: (
          <AiOutlineWarning
            style={{ marginRight: "0.5em", color: "red", alignSelf: "center" }}
          />
        ),
      });
    }
  };

  const handleResendOTP = async () => {
    try {
      setLoading(true);
      const response = await FrontDeskApi.otpCodeSend({
        email: email ?? "",
      });
      setDisableRetry(true);
      setLoading(false);
      setModalData({
        title: Strings.OTP_SENT,
        children: Strings.NEW_OTP_SENT,
        onClose: undefined,
        onAccept: undefined,
        headerIcon: (
          <AiOutlineCheckCircle
            style={{
              marginRight: "0.5em",
              color: "green",
              alignSelf: "center",
            }}
          />
        ),
      });
    } catch (error: any) {
      setDisableRetry(false);
      setLoading(false);
      setModalData({
        title: Strings.ERROR_SENDING_NEW_OTP,
        children: error.message,
        onClose: undefined,
        onAccept: undefined,
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior (form submission)
      sendOTPCode(); // Call sendOTPCode when Enter key is pressed
    }
  };

  return (
    <div>
      <div className="login-container">
        <Image className="custom-logo" src={Logo} />
        <h2
          style={{
            marginTop: 10,
          }}>
          {Strings.VERIFICATION}
        </h2>
        <p style={{ textAlign: "center", color: "#858383" }}>
          {Strings.ENTER_EMAIL_NUMBER}
        </p>
        <div className="inputPhoneNumber">
          <Form>
            <Form.Group controlId="formBasicEmail">
              <FormControl
                type="text"
                placeholder={Strings.EMAIL_ADDRESS}
                value={email}
                onChange={handleEmailChange}
                className="input input-large"
                isInvalid={!isValid}
                isValid={sendClicked}
                ref={inputRef}
                onKeyDown={handleKeyPress}
                required
              />
            </Form.Group>
          </Form>
          <button
            type="button"
            className="btn sendOtpbtn"
            onClick={sendOTPCode}>
            {Strings.SEND_OTP}
          </button>
        </div>
        {sendClicked && (
          <div className="otpContainer" onClick={() => setSendClicked(false)}>
            <div className="otpContainer" onClick={handleClick}>
              {disableRetry ? (
                <CountdownCircleTimer
                  key={timerKey}
                  isPlaying
                  size={65}
                  strokeWidth={4}
                  duration={60}
                  rotation="counterclockwise"
                  colors="#D69188"
                  onComplete={() => {
                    setDisableRetry(false);
                    return { shouldRepeat: false, delay: 1 };
                  }}>
                  {({ remainingTime }) => <span>{remainingTime}</span>}
                </CountdownCircleTimer>
              ) : null}
              <div className="mobileNumberInfo">
                <p>
                  {Strings.CODE_SENT_TO}
                  <span className="font-bold">{email}</span>
                </p>
              </div>
              <div className="otp">
                <OtpCodeInput
                  fields={4}
                  onChange={setOtpCode}
                  fieldWidth={50}
                  fieldHeight={60}
                />
                <div className="resendContainer">
                  <p className="text-grayText getCodeInfo">
                    {Strings.DID_NOT_GET_CODE}
                  </p>
                  <button
                    type="button"
                    className="btn btn-link custom-link"
                    disabled={disableRetry}
                    onClick={handleResendOTP}>
                    {Strings.RESEND}
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="btn verify-Btn"
                onClick={handleVerifyOtp}>
                {Strings.VERIFY}
              </button>
            </div>
          </div>
        )}
        <StaticBackdropModal
          children={modalData?.children}
          title={modalData?.title}
          handleClose={modalData?.onClose ?? handleCloseModal}
          show={!!modalData}
          headerIcon={modalData?.headerIcon}
          btnText={modalData?.btnText}
          handleOpen={modalData?.onAccept}
        />
        <LoadingIndicator loading={loading} />
      </div>
    </div>
  );
};

export default LoginPage;
